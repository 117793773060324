<template>
  <div class="ivp" ref="ivheight" v-if="ispc">
    <div class="center">
      <div class="center_box">
        <div class='text_header'>
          <div class="text_position">
            <p>IoV  Integrated Management Platform</p>
            <p>
              The platform integrates functions such as vehicle positioning and scheduling, 
              driving behavior data, fuel management, fault diagnosis, 4G video return, 
              and deeply excavates the data value of vehicles. Through cloud computing and big data technology, 
              a large number of vehicle information can be analyzed and processed in real time. 
              Provide more humanized intelligent Internet of vehicles services for manufacturers, drivers and road managers.
            </p>
            <img src="@/assets/img/logo/ivp.png">
          </div>
        </div>
      </div>
    </div>
    <div class="waitingforbox">
      <div class="waitingfor">
        <div class="waitingfortext">
          <div>What are you waiting for?</div>
          <p>Let's try the Intelligent Monitoring Platform for free</p>
        </div>
        <el-button @click="addfree">
          <span>
            Free Trial
            <img src="@/assets/img/logo/btnlogo.png">
          </span>
        </el-button>
      </div>
    </div>
    <footers></footers>
  </div>
  <div class="ivp" ref="ivheight" v-else>
    <div class="center">
      <div class="center_box center_box1">
        <div class='text_header'>
          <div class="text_position">
            <p>IoV  Integrated Management Platform</p>
            <p>
              The platform integrates functions such as vehicle positioning and scheduling, 
              driving behavior data, fuel management, fault diagnosis, 4G video return, 
              and deeply excavates the data value of vehicles. Through cloud computing and big data technology, 
              a large number of vehicle information can be analyzed and processed in real time. 
              Provide more humanized intelligent Internet of vehicles services for manufacturers, drivers and road managers.
            </p>
            <img src="@/assets/img/logo/ivp.png">
          </div>
        </div>
      </div>
    </div>
    <div class="waitingforbox waitingforbox1">
      <div class="waitingfor">
        <div class="waitingfortext">
          <div>What are you waiting for?</div>
          <p>Let's try the Intelligent Monitoring Platform for free</p>
        </div>
        <el-button @click="addfree">
          <span>
            Free Trial
            <img src="@/assets/img/logo/btnlogo.png">
          </span>
        </el-button>
      </div>
    </div>
    <footers></footers>
  </div>
</template>

<script>
import headers from "@/components/header_white.vue"
import footers from "@/components/footers.vue"
export default {
  name: "ivp",
  data() {
    return {
      ispc:true,
    };
  },
  components:{
    footers,
    headers
  },
  mounted(){
    this.pmwidth()
  },
  methods: {
    addfree(){
      this.$router.push('/FreeTrial')
    },
    pmwidth(){
      let w = document.documentElement.clientWidth || document.body.clientWidth;
      if(w <= 900){
        this.$refs.ivheight.style.height = '60vh'
        this.ispc = false
      }else{
        this.$refs.ivheight.style.height = '100vh'
        this.ispc = true
      }
    },
  },
};
</script>


<style scoped lang="scss">
.ivp{
  width:100%;
  height:100vh;
  .center{
    width:100%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top:110px;
    .center_box{
      width:80%;
      height:750px;
      background: #0D8AF2;
      border-radius:20px;
      padding-top:80px;

      .text_header{
        width:100%;
        height:100%;
        font-family: 'Montserrat-light';
        font-style: normal;
        font-size: 26px;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        .text_position{
          width:60%;
          height:100%;
          position:relative;
          p:nth-child(1){
            text-align: center;
            font-family: 'Montserrat-light';
            font-style: normal;
            font-weight: 900;
            font-size: 44px;
          }
          p:nth-child(2){
            width:100%;
            text-align: center;
            color:#dedede;
            font-family: 'Montserrat';
            font-style: normal;
            font-size: 23px;
            margin-top:50px;
            line-height:33px;
          }
          img{
            width:94%;
            height:52%;
            position:absolute;
            bottom:0;
            left:50%;
            transform: translate(-50%);
          }
        }
      }
    }
    .center_box1{
      height:1050px;
      .text_header{
        width:100%;
        height:100%;
        font-family: 'Montserrat-light';
        font-style: normal;
        font-size: 26px;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        .text_position{
          width:78%;
          height:100%;
          position:relative;
          p:nth-child(1){
            text-align: center;
            font-family: 'Montserrat-light';
            font-style: normal;
            font-weight: 900;
            font-size: 60px;
          }
          p:nth-child(2){
            width:100%;
            text-align: center;
            color:#dedede;
            font-family: 'Montserrat';
            font-style: normal;
            font-size: 43px;
            margin-top:50px;
            line-height:43px;
          }
          img{
            width:90%;
            height:41%;
          }
        }
      }
    }
  }
}

.waitingforbox{
  width:100%;
  height:220px;
  display: flex;
  justify-content: center;
  margin-bottom:120px;
  margin-top:200px;
  .waitingfor{
    width:80%;
    height:100%;
    background: #0D8AF2;
    border-radius: 40px;
    display: flex;
    align-items: center;
    position:relative;
    .waitingfortext{
      position:absolute;
      left:11%;
      color:#fff;
      div{
        font-family: 'Montserrat-light';
        font-style: normal;
        font-weight: 900;
        font-size: 30px;
        margin-bottom:30px;
      }
      p{
        font-family: 'Montserrat-light';
        font-style: normal;
        font-weight: 500;
        font-size:18px;
      }
    }
    .el-button{
      position:absolute;
      left:70%;
      width:270px;
      height:65px;
      padding:0;
      font-family: 'Montserrat-light';
      font-style: normal;
      font-weight: 900;
      font-size: 20px;
      text-align: center;
      text-transform: uppercase;
      background: #FFA000;
      color:#fff;
      border-radius: 10px;
      border:0;
      span{
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Montserrat-light';
        font-style: normal;
        font-weight: 900;
      }
      img{
        margin-left:20px;
        width: 25px;
        height:23px;
      }
    }
    .el-button:hover{
      background:#FF8A00;
    }
  }
}

.waitingforbox1{
  margin-top:0;
  height:350px;
  margin-bottom:230px;
  .waitingfortext{
    position:absolute;
    left:7% !important;
    color:#fff;
    div{
      font-family: 'Montserrat-light';
      font-style: normal;
      font-weight: 900;
      font-size: 50px !important;
      margin-bottom:30px;
    }
    p{
      font-family: 'Montserrat-light';
      font-style: normal;
      font-weight: 500;
      font-size:30px !important;
    }
  }
  .el-button{
    position:absolute;
    left:70%;
    width:350px !important;
    height:90px !important;
    padding:0;
    font-family: 'Montserrat-light';
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    background: #FFA000;
    color:#fff;
    border-radius: 10px;
    border:0;
    span{
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: 'Montserrat-light';
      font-style: normal;
      font-weight: 900;
      font-size:38px;
    }
    img{
      margin-left:20px;
      width: 25px;
      height:23px;
    }
  }
}


</style>